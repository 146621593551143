var Aneox;
(function (Aneox) {
  (function (Library) {
    'use strict';

    var Analytics = (function () {

      var self;

      function Analytics(router) {
        self = this;
        
        this.router = router;
        
        if (_.isEmpty(Aneox.Data.Config.analytics)) return;

		    if (!_.isEmpty(Aneox.Data.Config.analytics.google)) {
              (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
              (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*(new Date());a=s.createElement(o),
              m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
              })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

              ga('create', Aneox.Data.Config.analytics.google, 'auto');
              this.googleAnalyticsEnabled = true;
          }
          
        this.router.bind('pageChange', function (path, node, nodeLocale) {
          if (self.googleAnalyticsEnabled) {
            ga('send', 'pageview', path.path);
          }
        });
      }

      MicroEvent.mixin(Analytics);

      return Analytics;
    })();

    Library.Analytics = Analytics;
  })(Aneox.Library || (Aneox.Library = {}));
})(Aneox || (Aneox = {}));