var Aneox;
(function (Aneox) {
    'use strict';

    var Animations = (function () {

        function Animations() {
            this.timeline = new TimelineMax();
        }

        return Animations;
    })();

    Aneox.Animations = Animations;
})(Aneox || (Aneox = {}));