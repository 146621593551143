var Aneox;
(function(Aneox) {
    (function(Library) {
        'use strict';

        var Fonts = (function() {

            function Fonts() {
                if (_.isEmpty(Aneox.Data.Config.fonts)) return;

                if (Aneox.Data.Config.fonts.custom) {
                    if (_.get(Aneox.Data.Config.fonts, 'google.families') && !_.isArray(Aneox.Data.Config.fonts.google.families))
                        Aneox.Data.Config.fonts.google.families = [Aneox.Data.Config.fonts.google.families];
                        
                    if (_.get(Aneox.Data.Config.fonts, 'custom.families') && !_.isArray(Aneox.Data.Config.fonts.custom.families))
                        Aneox.Data.Config.fonts.custom.families = [Aneox.Data.Config.fonts.custom.families];

                    if (_.get(Aneox.Data.Config.fonts, 'custom.urls') && !_.isArray(Aneox.Data.Config.fonts.custom.urls))
                        Aneox.Data.Config.fonts.custom.urls = [Aneox.Data.Config.fonts.custom.urls];

                    Aneox.Data.Config.fonts.custom.urls = _.map(Aneox.Data.Config.fonts.custom.urls, function(url) { return (rootPath || '') + url; });
                }

                var self = this;
                Aneox.Data.Config.fonts.active = function() {
                    self.trigger('fontsActive');
                };

                WebFont.load(Aneox.Data.Config.fonts);
            }

            MicroEvent.mixin(Fonts);

            return Fonts;
        })();

        Library.Fonts = Fonts;
    })(Aneox.Library || (Aneox.Library = {}));
})(Aneox || (Aneox = {}));